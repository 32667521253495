var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vodal",
    {
      staticClass: "request_form_modal",
      attrs: {
        show: _vm.show,
        animation: "fade",
        customStyles: { width: "auto", height: "auto" }
      },
      on: { hide: _vm.closeModal }
    },
    [
      _c("RequestForm", {
        class: "test-test",
        on: { closeModal: _vm.closeModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }