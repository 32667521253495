<template>
	<div>
		<form
			class="form offline-form"
			id="contact-form"
			v-on:submit.prevent="submitForm"
		>
			<input
				type="hidden"
				ref="formname"
				data-value="Форма на главной"
				name="formname"
			/>
			<div class="form-content">
				<input
					type="text"
					v-model="form.contact"
					class="textfield"
					maxlength="256"
					name="contact"
					:placeholder="translate('Email или телефон')"
					required="required"
					v-if="LOCALE == 'ru'"
				/>

				<div class="form-input phone-input" v-else>
					<!-- <input
						:id="`phone_input`"
						v-model="form.contact"
						type="tel"
					> -->
					<vue-tel-input
						v-model="formPhoneNumber.phone"
						:inputClasses="errorMessage.phone_number ? 'error' : ''"
						:placeholder="'Phone number'"
						:autoFormat="true"
						:valid-characters-only="true"
						:defaultCountry="'GB'"
						@country-changed="getCountry"
						@onInput="isValid"
						:invalidMsg="'Wrong number'"
					></vue-tel-input>
				</div>
				<button
					type="submit"
					:disabled="is_submit"
					class="orange-button button button-outline"
				>
					{{ translate('Отправить') }}
				</button>
			</div>
			<div class="checkbox-block orange">
				<input
					type="checkbox"
					name="form_offer_agreement"
					id="form_offer_agreement"
					checked="checked"
					required
				/>
				<label for="form_offer_agreement">
					{{ translate('Отправляя форму, я даю') }}
					<a
						:href="
							translate(
								'/politika-obrabotki-personalnyih-dannyih/'
							)
						"
						target="_blank"
						>{{
							translate(
								'согласие на обработку персональных данных'
							)
						}}</a
					>
				</label>
			</div>
		</form>
		<SubmitFormModal :show="show" :text="modal_text" @closed="closeModal" />
	</div>
</template>

<script>
import Vue from 'vue'
import { VueTelInput } from 'vue-tel-input'

import qs from 'qs'
import axios from 'axios'

import SubmitFormModal from '../modals/submit_form_modal.vue'
import { translate } from '../../translates.js'
import Snackbar from '../../snackbar.js'
import urlFilter from '../../urlFilter.js';


Vue.use(VueTelInput)

export default {
	name: 'ContactForm',
	data: function () {
		return {
			LOCALE: LOCALE,
			show: false,
			form: {
				csrfmiddlewaretoken: window.__app.csrf_token,
				contact: '',
				formname: '',
			},
			is_submit: false,
			modal_text: '',
			formPhoneNumber: {
                dialCode: '+44',
                country: 'United Kingdom',
                phone: null,
            },
            isChecked: true,
            errorMessage: {
                phone_number: false,
                isValid: false
            }
		}
	},

	components: {
		SubmitFormModal,
		VueTelInput,
	},
	methods: {
		isValid({ isValid }) {
            this.errorMessage.isValid = isValid
            console.log(this.errorMessage.isValid)
        },
        getCountry(item) {
            this.formPhoneNumber.dialCode = item.dialCode
            this.formPhoneNumber.country = item.name
            // this.form.contact = `+${this.formPhoneNumber.dialCode}${this.formPhoneNumber.phone} Country: ${this.formPhoneNumber.country}`
            // console.log('begin',this.form.contact)
        },
		translate: function (msg) {
			return translate(msg)
		},
		openModal: function (text) {
			this.show = true
			this.modal_text = text
		},
		closeModal: function () {
			this.show = false
			this.modal_text = ''
		},
		submitForm: async function () {
			this.is_submit = true

			// formPhoneNumber: {
            //     dialCode: '+44',
            //     country: 'United Kingdom',
            //     phone: null,
            // },

			this.form.contact = `+${this.formPhoneNumber.dialCode} ${this.formPhoneNumber.phone} Country: ${this.formPhoneNumber.country}`

			let formData = this.form
			if (window.__app.recaptcha_enabled) {
				const rtk = await window.__app.recaptcha_reset()
				formData = { ...formData, rtk } // Object.assign({ rtk: window.__app.rtk }, formData); //
			}

			const data = qs.stringify(formData),
				url = window.__app.submit_client_form

			// SubmitFormModal.openModal("text");
			axios
				.post(url, data)
				.then((response) => {
					let currentLocation = window.location
					window.ym &&
						ym(window.ya_id, 'reachGoal', 'anyForm', {
							URL: urlFilter(document.location.href),
						})
					window.gtag &&
						gtag('event', 'submitLeadForm', {
							event_category: 'Leads',
						})
					if (currentLocation.pathname === '/author_compensation/') {
						window.ym &&
							ym(window.ya_id, 'reachGoal', 'authorRequest', {
								URL: urlFilter(document.location.href),
							})
						window.gtag &&
							gtag('event', 'request', {
								event_category: 'author',
							})
					} else {
						window.ym &&
							ym(window.ya_id, 'reachGoal', 'clientRequest', {
								URL: urlFilter(document.location.href),
							})
						window.gtag &&
							gtag('event', 'request', {
								event_category: 'client',
							})
					}
					this.openModal(response.data.result)
				})
				.catch((error) => {
					Snackbar(
						translate(
							'Извините, произошла ошибка, попробуйте еще раз'
						)
					)
				})
				.finally(() => {
					this.is_submit = false
				})
		},
	},
	mounted() {
		this.form.formname = this.$refs.formname.dataset.value
	},
}
</script>
