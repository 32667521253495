<template>
  <vodal
    :show="show"
    @hide="closeModal"
    animation="fade"
    class="request_form_modal"
    v-bind:customStyles="{width: 'auto', height: 'auto'}"
  >
    <RequestForm @closeModal="closeModal" :class="'test-test'"/>
  </vodal>
</template>

<script>
import Vue from "vue";
import Vodal from "vodal";

import RequestForm from "../../removing_en/OrderFormV2.vue";

Vue.component(Vodal.name, Vodal);
export default {
  name: "RequestFormModal",
  props: ["show"],
  data() {
    return {};
  },
  components: {
    RequestForm,
  },
  methods: {
    closeModal: function () {
      this.$emit("closed", true);
    },
  },
};
</script>