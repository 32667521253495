<template>
    <div :class="this.class_name">
        <div class="modal-title" v-if="this.title">
            {{this.title}}
        </div>
        <form
			class="offline-order-form"
			method="post"
			v-on:submit.prevent="submitForm"
		>
            <div class="form-name">
                <input 
                    type="text" 
                    placeholder="Name"
                    v-model="form.user_name"
                    :class="errorMessage.name  ? 'error' : ''"
                >
            </div>
            <div class="form-contacts">
                <div class="form-email">
                    <input 
                        type="text"
                        placeholder="Email"
                        v-model="form.email"
                        :class="errorMessage.email ? 'error' : ''"
                    >
                </div>
                    <!-- <input
                        v-model="form.contact" 
                        :class="errorMessage.phone_number ? 'error' : ''"
                        type="tel" 
                        name="phone" 
                        id=""
                        placeholder="Phone number"
                    > -->
                <vue-tel-input
                    v-model="formPhoneNumber.phone"
                    :inputClasses="errorMessage.phone_number ? 'error' : ''"
                    :placeholder="'Phone number'"
                    :defaultCountry="'GB'"
                    @country-changed="getCountry"
                ></vue-tel-input>
            </div>
            <div class="form-description">
                <textarea 
                    placeholder="Please describe your situation"
                    v-model="form.problem"
                />
            </div>
            <div class="form-submit-container">
                <div class="checkbox-container">
                    <input type="checkbox" v-model="isChecked">
                    <label for="privacy_checker">
                        By submitting the form I consent to the 
                        <a href="https://digitalsharks.co.uk/privacy-policy/" target="_blank">Privacy Policy</a>
                    </label>
                </div>
                <button type="submit" :class="is_submit ? 'button-after-click' : ''">
                    <span>Submit</span>
                    <div class="animation-circle" :class="is_submit ? 'animation-circle-start' : ''"></div>
                </button>
            </div>
        </form>
        <div>
            <!-- <img src="/static/images/removing_en/reload.png" alt=""> -->
            
        </div>
        <div v-if="errorMessage.length" style="color: white">
            <div v-for="(value, key) in errorMessage" :key="key">
                {{value}}
            </div>
        </div>

        <SubmitFormModal
            :show="showSubmitSuccessForm"
            :text="submitSuccessText"
            @closed="closeSubmitSuccssModal"
        />
    </div>
    
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import Snackbar from '../snackbar.js'
import { translate } from '../translates.js'
import { urlFilter } from '../url_filter.js'
import { getUuid } from '../utils.js'
import {VueTelInput} from 'vue-tel-input'
import SubmitFormModal from '../components/modals/submit_form_modal.vue'


export default {
    name: 'OrderFormV2',
    components: {
        'vue-tel-input':VueTelInput,
        'SubmitFormModal':SubmitFormModal
    },
    props: {
        'title': {
            type: String, 
            default: ' '
        },
        'class_name': {
            type: String, 
            default: 'form-container'
        }
    },
    data() {
        return {
            uuid: getUuid(),
			is_submit: false,
			showSubmitSuccessForm: false,
			submitSuccessText: '',
            isDisabled: false,
            formPhoneNumber: {
                country: 'United Kingdom',
                dialCode: '+44'
            },
            form: {
                csrfmiddlewaretoken: window.__app.csrf_token,
                user_name: null,
                email: null,
                contact: null,
                problem: ''
            },
            isChecked: true,
            errorMessage: {
                name: false,
                email: false,
                phone_number: false,
                isValid: false
            }
        }
    },
    methods: {
        animateCircle() {

        },
        // isValid({ isValid }) {
        //     console.log(isValid)
        //     this.errorMessage.isValid = isValid
        // },
        getCountry(item) {
            this.formPhoneNumber.country = item.name
            this.formPhoneNumber.dialCode = item.dialCode
        },
        checkForm(form) {
            if (form.user_name && form.email && this.formPhoneNumber.phone) {
                return true
            }

            else {
                if (!form.user_name) {
                    this.errorMessage.name = true
                }
                if (!form.email) {
                    this.errorMessage.email = true
                }
                if (!this.formPhoneNumber.phone) {
                    this.errorMessage.phone_number = true
                }

                return false
            }
        },
        submitForm: async function () {
            if (!this.checkForm(this.form)) {
                return
            }

            this.form.contact = `+${this.formPhoneNumber.dialCode} ${this.formPhoneNumber.phone} Country: ${this.formPhoneNumber.country}`
			this.is_submit = true
			this.form.link = this.orderLink

			let formData = this.form

			if (window.__app.recaptcha_enabled) {
				// const rtk = await window.__app.recaptcha_reset()
                // rtk
				formData = { ...formData }
			}

            this.isDisabled = true

			const data = qs.stringify(formData),
				url = window.__app.submit_client_form

			axios
				.post(url, data)
				.then((response) => {
					window.ym &&
						ym(87591880, 'reachGoal', 'anyForm', {                            
							URL: urlFilter(document.location.href),
						})
                    
					window.ym &&
						ym(87591880, 'reachGoal', 'offlineSubmit', {
							URL: urlFilter(document.location.href),
						})

					window.gtag &&
						gtag('event', 'request', {
							event_category: 'any',
						})
                    
					window.gtag &&
						gtag('event', 'submitLeadForm', {
							event_category: 'Leads',
						})
                    
                    this.openSubmitSuccessModal(response.data.result)

				})
				.catch((error) => {
					Snackbar(
						translate(
							`Sorry there is an error ${error}`
						)
					)
				})
				.finally(() => {
					this.is_submit = false
                    this.form.user_name = ''
                    this.form.email = ''
                    this.form.contact = ''
                    this.form.problem = ''
                    this.isDisabled = false
				})
		},
        openSubmitSuccessModal: function (text) {
			this.submitSuccessText = text
			this.showSubmitSuccessForm = true
		},
		closeSubmitSuccssModal: function () {
			this.showSubmitSuccessForm = false
		},
    }
}
</script>

<style scoped>
    
</style>