var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "form offline-form",
          attrs: { id: "contact-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("input", {
            ref: "formname",
            attrs: {
              type: "hidden",
              "data-value": "Форма на главной",
              name: "formname"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "form-content" }, [
            _vm.LOCALE == "ru"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.contact,
                      expression: "form.contact"
                    }
                  ],
                  staticClass: "textfield",
                  attrs: {
                    type: "text",
                    maxlength: "256",
                    name: "contact",
                    placeholder: _vm.translate("Email или телефон"),
                    required: "required"
                  },
                  domProps: { value: _vm.form.contact },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "contact", $event.target.value)
                    }
                  }
                })
              : _c(
                  "div",
                  { staticClass: "form-input phone-input" },
                  [
                    _c("vue-tel-input", {
                      attrs: {
                        inputClasses: _vm.errorMessage.phone_number
                          ? "error"
                          : "",
                        placeholder: "Phone number",
                        autoFormat: true,
                        "valid-characters-only": true,
                        defaultCountry: "GB",
                        invalidMsg: "Wrong number"
                      },
                      on: {
                        "country-changed": _vm.getCountry,
                        onInput: _vm.isValid
                      },
                      model: {
                        value: _vm.formPhoneNumber.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.formPhoneNumber, "phone", $$v)
                        },
                        expression: "formPhoneNumber.phone"
                      }
                    })
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "orange-button button button-outline",
                attrs: { type: "submit", disabled: _vm.is_submit }
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.translate("Отправить")) + "\n\t\t\t"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox-block orange" }, [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: "form_offer_agreement",
                id: "form_offer_agreement",
                checked: "checked",
                required: ""
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "form_offer_agreement" } }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.translate("Отправляя форму, я даю")) +
                  "\n\t\t\t\t"
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.translate(
                      "/politika-obrabotki-personalnyih-dannyih/"
                    ),
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.translate("согласие на обработку персональных данных")
                    )
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("SubmitFormModal", {
        attrs: { show: _vm.show, text: _vm.modal_text },
        on: { closed: _vm.closeModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }