export default function urlFilter(url) {
    let isNormal = true
    let notFiltered = url.split('/'), 
    filtered;
    let deleteEmptys = notFiltered.filter(item => item.length !== 0)
    deleteEmptys[0] = deleteEmptys[0] + '/'

    deleteEmptys.forEach(el => {
        if (el.startsWith('?')) {
            filtered = deleteEmptys.filter(item => item !== el)
            isNormal = false
        }
    });
    
    if (isNormal) {
        return url
    }

    let filteredUrl = '';
    filtered.forEach(item => {
        filteredUrl = filteredUrl + item + '/'
    });

    return filteredUrl
}
